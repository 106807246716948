<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.status" class="tabsWrap" @change="handleTabs">
      <el-radio-button :label="0">全部</el-radio-button>
      <el-radio-button :label="1">待开始({{ table.total_1 }})</el-radio-button>
      <el-radio-button :label="2">进行中({{ table.total_2 }})</el-radio-button>
      <el-radio-button :label="3">已结束({{ table.total_3 }})</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="商品名称" prop="goods_name">
          <el-input
            size="mini"
            v-model.trim="table.params.goods_name"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动日期" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
             @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showAdd">特价商品</el-button>
        <el-button :size="subUnitSize" type="primary" plain @click="batchFinish">批量结束</el-button>
        <el-button :size="subUnitSize" type="primary" plain @click="batchRemover" v-if="is_sys == 1">批量删除</el-button>
      </div>
    </div>
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      :showIndex="true"
      :updateKey="updateKey"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:goods_name="{row}">
        <div style="display:flex;align-items: center;">
          <el-image
            v-if="!!row.image"
            style="width: 40px; height: 40px"
            :src="row.image"
            fit="cover"
            :preview-src-list="[row.image]"
          ></el-image>
          <el-image
            v-else
            style="width: 40px; height: 30px"
            :src="require('@/assets/img/logo-icon.jpeg')"
            fit="cover"
            :preview-src-list="[require('@/assets/img/logo-icon.jpeg')]"
          ></el-image>
          <span style="margin-left: 10px">{{ row.goods_name }}</span>
        </div>
      </template>
      <template v-slot:status="{row}">
        <span :class="toStr({0:'',1:'', 2:'sussBtn', 3:'delBtn'},row.status) ">{{ toStr({0:'',1:'待开始', 2:'进行中', 3:'已结束'},row.status)}}</span>
      </template>
      <template v-slot:start_time="{row}">
        <div v-if="!!row.start_time">{{row.start_time}}开始</div>
        <div v-if="!!row.end_time">{{row.end_time}}结束</div>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="handleFinish(row)" v-if="row.status == 2">结束</el-button>
        <template v-if="row.status == 1">
          <el-button type="text" @click="showEdit(row)">编辑</el-button>
          <el-button type="text" @click="remover(row)" v-if="is_sys == 1">删除</el-button>
        </template>
      </template>
    </VTable>

    <el-dialog width="540px" title="编辑特价商品" :visible.sync="dialogFormVisible">
      <el-form 
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom1'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize">
        <el-rol>
          <el-col :span="12">
            <el-form-item label="商品名称:" prop="goods_name">
              <div>{{form.data.goods_name}}</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格:" prop="name">
              <div>{{form.data.name}}</div>
            </el-form-item>
          </el-col>
        </el-rol>
        <el-form-item label="活动时间" prop="time">
          <el-date-picker
            v-model="form.data.time"
            type="datetimerange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
            id="date"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="售价/元" prop="amount">
          <el-input v-model="form.data.amount" placeholder="请输入" @input="form.data.amount=form.data.amount.match(/\d+(\.\d{0,2})?/) ? form.data.amount.match(/\d+(\.\d{0,2})?/)[0] : ''">
            <span slot="suffix">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="限购数量" prop="limit">
          <el-input v-model="form.data.limit" placeholder="请输入" @input="form.data.limit=form.data.limit.match(/\d+(\.\d{0,5})?/) ? form.data.limit.match(/\d+(\.\d{0,5})?/)[0] : ''"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑 -->
    <edit ref="edit"  @refresh="getTable"></edit>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import { mixinTable } from '@/mixins/table.js'
import common from '@/util'
export default {
  name: 'SpecialIndex',
  components: {
    VTable,
    Edit
  },
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      loading: false,
      field: [
        { name: "goods_name", label: "商品名称", width:'185', hidden: false },
        { name: "unit_name", label: "单位", width:'80', hidden: false },
        { name: "name", label: "规格", width:'80', showTooltip: true, hidden: false},
        { name: "amount", label: "售价(元)", hidden: false},
        { name: "limit", label: "限购数量", hidden: false},
        { name: "sales", label: "销量", width:'80',hidden: false},
        { name: "start_time", label: "活动时间",width:'170', hidden: false},
        { name: "status", label: "活动状态", hidden: false},
        { name: "channel_name", label: "活动渠道", hidden: false},
        { name: "create_name", label: "创建人", width:'120', hidden: false},
        { name: "create_time", label: "创建时间", width:'160', hidden: false},
        { name: "action", label: "操作",width: "140", fixed:"right", hidden: false }
      ],
      multipleSelection:[],
      table: {
        loading: false,
        params: {
          goods_name: '',
          date: '',
          status: 0,
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        total_1: 0,
        total_2: 0,
        total_3: 0,
      },
      updateKey:new Date()+'',
      dialogFormVisible: false,
      form:{
        loading: false,
        data:{
          id: '', //ID
          time:[],
          channel: '', //渠道
          spec_id: "", //规格ID
          amount: "", //售价
          limit: "" //限购数量
        },
        rules: {
          time:  { required: true, message: '必填项', trigger: 'blur' },
          amount:  { required: true, message: '必填项', trigger: 'blur' },
        },
      }
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _params = {...this.table.params}
      this.table.loading = true;
      this.$http.get('/admin/special_goods/list', {params:_params}).then(res => {
        if(res.code === 1) {
          this.updateKey = new Date()+'';
          this.table.loading = false;
          this.table.data = res.data.list.map(v => {
            return{
              ...v,
              limit: v.limit == 0 ? '' : v.limit,
            }
          });
          this.table.total = res.data.total;
          this.table.total_1 = res.data.total_1;
          this.table.total_2 = res.data.total_2;
          this.table.total_3 = res.data.total_3;
        }
      })
    },
    handleTabs() {
      if(this.table.params.status == 3) {
        this.field = [...this.field.slice(0, -1)];
      } else {
        this.$nextTick(() => {
          this.field = [
            { name: "goods_name", label: "商品名称", width:'185', hidden: false },
            { name: "unit_name", label: "单位", width:'80', hidden: false },
            { name: "name", label: "规格", width:'80', showTooltip: true, hidden: false},
            { name: "amount", label: "售价(元)", hidden: false},
            { name: "limit", label: "限购数量", hidden: false},
            { name: "sales", label: "销量", width:'80',hidden: false},
            { name: "start_time", label: "活动时间",width:'170', hidden: false},
            { name: "status", label: "活动状态", hidden: false},
            { name: "channel_name", label: "活动渠道", hidden: false},
            { name: "create_name", label: "创建人", width:'120', hidden: false},
            { name: "create_time", label: "创建时间", width:'160', hidden: false},
            { name: "action", label: "操作",width: "140", fixed:"right", hidden: false }
          ]
        })
      }
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.table.data = [];
      this.getTable();
    },
   
    showAdd(row) {
      let dom = this.$refs.edit;
      dom.toggle(true)
      if(!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    showEdit(row) {
      this.dialogFormVisible = true;
      common.deepClone(this.form.data,row);
      this.form.data.time = [row.start_time,row.end_time];
    },
    submitForm() {
      this.$refs.elFormDom1.validate(valid => {
        if(valid) {
          if(this.form.data.limit === 0 || this.form.data.limit === '0') {
            return this.$message.warning("限购数量不能为0")
          }
          let _params = {
            id:this.form.data.id, //ID
            channel:this.form.data.channel, //渠道
            spec_id: this.form.data.spec_id, //规格ID
            amount: this.form.data.amount, //售价
            limit: this.form.data.limit, //限购数量
            start_time: this.form.data.time[0],
            end_time: this.form.data.time[1]
          }
          console.log(_params)
          this.$http.post('/admin/special_goods/edit',_params).then(res => {
            if(res.code == 1) {
              this.$message.success("操作成功！")
              this.dialogFormVisible = false;
              this.getTable()
            }
          })
        }
      })
    },
    remover(row) {
      this.setConfirm(
        "确定删除商品特价吗?",
        { id: row.id },
        "/admin/special_goods/delete"
      );
    },
    handleFinish(row) {
      this.setConfirm(
        "确定结束商品特价吗?",
        { id: row.id },
        "/admin/special_goods/finish"
      );
    },
    batchFinish() {
      let _ids = this.multipleSelection.map(v => v.id).join(",")
      if(this.multipleSelection.length > 0) {
        this.setConfirm(
          "确定批量结束商品特价码？",
          {id: _ids},
          "/admin/special_goods/finish"
        )
      }else {
        this.$message.warning("请至少选择一条数据！")
      }
    },
    batchRemover() {
      let _ids = this.multipleSelection.map(v => v.id).join(",")
      if(this.multipleSelection.length > 0) {
        this.setConfirm(
          "确定批量删除商品特价码？",
          {id: _ids},
          "/admin/special_goods/delete"
        )  
      }else {
        this.$message.warning("请至少选择一条数据！")
      }
    },
  }
}
</script>